<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

:root {
  --blue: #1e90ff;
  --green: #2ed573;
  --red: #ff4757;
  --grey: #a4b0be;
  --accent: #dfe4ea;
}

html, body, #app {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

html{
  background-color: #000000;
  opacity: 1;
  background-image:  linear-gradient(#090909 1px, transparent 1px), linear-gradient(to right, #090909 1px, #000000 1px);
  background-size: 20px 20px;
}

#app {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

h1 {
  font-family: "Major Mono Display", serif;
  font-weight: 400;
  font-style: normal;
}
</style>
