<template>
  <div class="home">
    <h1> e-toR </h1>
    <input ref="search" type="text" placeholder="Search..."  @keyup.enter="search" />
    <button @click="search">Search</button>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: { },
  mounted() {
    this.$nextTick(() => {
      this.$refs.search.focus()
    })
  },
  methods: {
    search() {
      const search = this.$refs.search.value;
      if (search) {
        this.$router.push({ name: 'searchView', params: { query: search } });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
h1{
  margin: 0;
  padding: 0;
  font-size: 3em;
}
input{
  background-color: var(--accent);
  border: 2px solid var(--grey);
  opacity: 0.8;
  padding: 10px;
  border-radius: 20px;
  margin: 40px 0;
  width: 300px;
}

button{
  background-color: var(--accent);
  padding: 4px 10px;
  border-radius: 5px;
  border: none;
  color: black;
  cursor: pointer;
  background-color: white;

}
</style>

